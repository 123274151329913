<template>
  <div>
    <!-- <div class="xingqi1">星期一</div>
    <div class="xingqi2">星期二</div>
    <div class="xingqi3">星期三</div>
    <div class="xingqi4">星期四</div>
    <div class="xingqi5">星期五</div>
    <div class="xingqi6">星期六</div>
    <div class="xingqi7">星期日</div> -->
    <el-row :gutter="30" style="margin-left: 9%;width: 150%;">
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[0] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[1] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[2] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[3] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[4] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[5] }}</div></el-col>
  <el-col :span="2"><div class="grid-content bg-purple">星期{{ nextWeekDays[6] }}</div></el-col>
</el-row>
    <div id="myChart2" style="width: 100%; height: 400px;margin-top: -50px;"></div>
    <!-- <div> <echart3 style="height: 20%;width:80% ;margin-left: 12%;"/>  </div>   -->
  </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from 'axios';
//import echart3 from '../components/echart3.vue'
export default {
  data() {
    return {
      nextWeekDays: [],
      generatedArray288: [],
      generatedArray2881: [],
      generatedArray70: [],
      generatedArray701: [],
      generatedArray70panduan: [],
      generatedArray70panduan1: [],
      generatedArray180: [],
      generatedArray1801: [],
      generatedArray110: [],
      generatedArray1101: [],
      generatedArray70cha: [],
      generatedArray70cha1: [],
      anomalyArray:[],
      anomalyArray1:[],
      GM: [],
      GM1: [],
      GM2: [],
      GMNEXT: [],
      TWOid: '',
      startday: '',
      date1: 2015,
      date2: 4031,

      date3: 735,
      date4: 1008,

      starttime: '',
      cha: '',
    };
  },
  props: [
    'userid',
    'startday',
    'minute',
  ],
  components: {

  },
  created() {

    this.getMidCompute1();

  },

  mounted() {

    this.getMidCompute1();

    this.getweeks();
  },
  methods: {
    getweeks() {
      const startDate = new Date(this.startday);
      const daysOfWeek = ['日', '一', '二', '三', '四', '五', '六']
      if (!isNaN(startDate.getTime())) {
        // 初始化一个数组，用于存储一星期内每天的星期值


        // 循环计算一星期内每天的星期值
        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(startDate);
          currentDate.setDate(startDate.getDate() + i);
          this.nextWeekDays.push(daysOfWeek[currentDate.getDay()]);
        }
      } else {
        this.nextWeekDays = ['无效日期'];
      }
      // console.log(this.nextWeekDays)
    },

    ADD1() {
      this.processArray21();
      this.processArray1();
      this.drawGraph1(this.generatedArray288, this.generatedArray70panduan, this.GM, this.GM2, this.generatedArray110, this.generatedArray180, this.generatedArray70cha,
          this.generatedArray2881, this.generatedArray70panduan1, this.generatedArray1101, this.generatedArray1801, this.generatedArray70cha1,this.anomalyArray,this.anomalyArray1)
      // console.log(this.generatedArray70cha)

    },

    //96//
    getMidCompute1() {
      axios.get('http://js.aps.ink:8090/selectbgFromOneDay', {
        // 传递的参数
        params: {
          id: this.userid,
          startday: this.startday,
        }
        // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
      }).then(res => {
        // 如果状态为400,说明登录失败
        if (res.data.status === 400) {
          // 登录失败提示

        } else {
          // 登录成功提示
          this.GM1 = res.data.mgList
          this.starttime = res.data.timeList[0];
          const hour = this.starttime.substring(11, 13)
          const fen = this.starttime.substring(14, 16)
          const move1 = parseInt(hour, 10)
          const move2 = parseInt(fen, 10)
          // console.log(move1);
          // console.log(fen);
          var move = Math.round(((move1 * 60) + move2) / 5);
          // console.log(move);
          this.cha = this.date3 - move;
          for (let i = 0; i < move; i++) {
            this.GM[i] = 0;
          }
          ;
          for (let i = move; i < this.date3; i++) {
            this.GM[i] = this.GM1[i - move];
          }
          ;
          const index = this.date3 - move
          this.GM2 = this.GM1.slice(index, this.GM1.length);
          // console.log(this.GM2);
          for (let i = this.GM1.length; i < this.date4; i++) {
            this.GM2[i] = 70;
          }
          ;

          this.ADD1()
        }
      }).catch(error => {
        console.log(error)
      })
    },


    processArray21() {
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] === 0) {
          this.generatedArray1101[i] = 0;
        }
        else {
          this.generatedArray1101[i] = 110;
        }

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        this.generatedArray2881[i] = "";

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] > 70) {
          this.generatedArray70panduan1[i] = 70
        } else {
          this.generatedArray70panduan1[i] = this.GM2[i]

        }
      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] < 180) {
          this.generatedArray1801[i] = 0
        } else {
          this.generatedArray1801[i] = this.GM2[i] - 180

        }
      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM2[i] > 70) {
          this.generatedArray70cha1[i] = 0
        } else if(this.GM2[i] === 0 ) {
          this.anomalyArray1[i]= 70
        }
        else{
          this.generatedArray70cha1[i] = 70 - this.GM2[i]
        }
      }
      ;
    },



    processArray1() {
      for (let i = 0; i < this.date3; i++) {
        if (this.GM[i] === 0) {
          this.generatedArray110[i] = 0;
        }
        else {
          this.generatedArray110[i] = 110;
        }

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        this.generatedArray288[i] = "";

      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM[i] > 70) {
          this.generatedArray70panduan[i] = 70
        } else {
          this.generatedArray70panduan[i] = this.GM[i]

        }
      }
      ;
      for (let i = 0; i < this.date3; i++) {
        if (this.GM[i] < 180) {
          this.generatedArray180[i] = 0
        } else {
          this.generatedArray180[i] = this.GM[i] - 180

        }
      }
      ;
      for (let i = 0; i < this.date1; i++) {
        if (this.GM[i] > 70) {
          this.generatedArray70cha[i] = 0
        } else if(this.GM[i] === 0){
          this.anomalyArray[i] = 70
        }
        else {
          this.generatedArray70cha[i] = 70 - this.GM[i]
        }
      }

    },


    drawGraph1(generatedArray288, generatedArray70panduan, GM, GM2, generatedArray110, generatedArray180, generatedArray70cha,
               generatedArray2881, generatedArray70panduan1, generatedArray1101, generatedArray1801, generatedArray70cha1,anomalyArray,anomalyArray1
    ) {
      const myChart = echarts.init(document.getElementById('myChart2'));
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        toolbox: {
          feature: {}
        },
        grid: [
          {
            left: '3%',
            right: '4%',
            top: '3%',
            bottom: '50%',  // 调整第一个网格的 bottom 属性
            containLabel: true
          },
          {
            left: '3%',
            right: '4%',
            top: '50%',  // 调整第二个网格的 top 属性
            bottom: '3%',  // 调整第二个网格的 bottom 属性
            containLabel: true
          },

        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: generatedArray288,
            gridIndex: 0
          },
          {
            type: 'category',
            boundaryGap: false,
            data: generatedArray2881,
            gridIndex: 1
          },

        ],
        yAxis: [
          {
            name: "毫克/分钟",
            type: 'value',
            gridIndex: 0

          },
          {
            type: 'value',
            gridIndex: 1

          },

        ],
        series: [
          // {
          //   name: 'Beijing AQI',
          //   type: 'line',
          //   xAxisIndex: 1, // 指定使用第二个x轴
          //   yAxisIndex: 1,// 指定使用第二个y轴,
          //   markLine: {
          //     silent: true,
          //
          //     lineStyle: {
          //       color: '#333'
          //     },
          //     data: [
          //       {
          //         yAxis: 70
          //       },
          //       {
          //         yAxis: 180
          //       },
          //     ]
          //   }
          // }
          // ,
          // {
          //   name: 'Beijing AQI',
          //   type: 'line',
          //   symbol: 'roundRect',
          //   xAxisIndex: 0, // 指定使用第二个x轴
          //   yAxisIndex: 0,// 指定使用第二个y轴,
          //   markLine: {
          //     silent: true,
          //
          //     lineStyle: {
          //       color: '#333'
          //     },
          //     data: [
          //       {
          //         yAxis: 70
          //       },
          //       {
          //         yAxis: 180
          //       },
          //     ]
          //   }
          // }
          // ,
          {
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 255, 255)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70panduan,
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 0, 0)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70cha,
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          // {
          //   type: 'line',
          //   stack: 'Total',
          //   smooth: true,
          //   symbol: 'none',
          //   areaStyle: {
          //     opacity: 0.8,
          //     color: 'rgb(187,190,187)'//异常
          //   },
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: anomalyArray,
          //   xAxisIndex: 0, // 指定使用第二个x轴
          //   yAxisIndex: 0 // 指定使用第二个y轴
          // },
          {
            type: 'line',
            stack: 'Total',
            smooth: false,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(208,243,199)'
            },
            lineStyle: {
              color: 'rgb(208,243,199)', // 设置边界线的颜色，例如绿色
              width: 2               // 设置边界线的宽度
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray110,
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            type: 'line',
            smooth: true,
            stack: 'Total',
            symbol: 'none',
            label: {
              show: false,
              position: 'top'
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(227,119,11)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray180.map(value => value === 0 ? null : value),
            xAxisIndex: 0, // 指定使用第二个x轴
            yAxisIndex: 0 // 指定使用第二个y轴
          },
          {
            name: "CGM",
            data: GM.map(value => value !== 0 ? value : null),  // 只绘制数据不等于0的点
            type: 'line',
            lineStyle: {
              color: 'rgb(45,112,141)',  // 曲线颜色
              width: 2                   // 曲线宽度
            },
            smooth: true,                // 平滑曲线
            symbol: 'none',              // 无数据点标记
            markLine: {
              symbol: ['none', 'none'],  // 无标记
              label: { show: false },    // 不显示标记线的标签
              data: [{xAxis: 288}, {xAxis: 576}, {xAxis: 864}, {xAxis: 1152}, {xAxis: 1440}, {xAxis: 1728}, {xAxis: 2010}]
            },
            markArea: {  // 添加标记区域来表示数据等于0的区域
              itemStyle: {
                color: 'rgba(124,118,118,0.5)',  // 矩形区域的颜色和透明度
                opacity: 0.3
              },
              z:100,
              data: GM.reduce((acc, value, index) => {  // 通过遍历 GM 数据生成等于0的区域
                if (value === 0) {
                  if (acc.length === 0 || acc[acc.length - 1][1].xAxis !== index - 1) {
                    // 如果是一个新的零值区域，则创建新的矩形
                    acc.push([{xAxis: index}, {xAxis: index + 1}]);
                  } else {
                    // 如果是同一个零值区域的延续，则扩展矩形的结束点
                    acc[acc.length - 1][1].xAxis = index + 1;
                  }
                }
                return acc;
              }, [])
            },
            xAxisIndex: 0,  // 使用第一个 x 轴
            yAxisIndex: 0   // 使用第一个 y 轴
          },
          {
            type: 'line',
            stack: 'Total1',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 255, 255)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70panduan1.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            type: 'line',
            stack: 'Total1',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(255, 0, 0)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray70cha1.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          // {
          //   type: 'line',
          //   stack: 'Total1',
          //   smooth: true,
          //   symbol: 'none',
          //   areaStyle: {
          //     opacity: 0.8,
          //     color: 'rgb(187,190,187)'//2异常
          //   },
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: anomalyArray1,
          //   xAxisIndex: 1, // 指定使用第二个x轴
          //   yAxisIndex: 1 // 指定使用第二个y轴
          // },
          {
            type: 'line',
            stack: 'Total1',
            smooth: false,
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(208,243,199)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray1101,
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            type: 'line',
            smooth: true,
            stack: 'Total1',
            symbol: 'none',
            label: {
              show: false,
              position: 'top'
            },
            areaStyle: {
              opacity: 0.8,
              color: 'rgb(227,119,11)'
            },
            emphasis: {
              focus: 'series'
            },
            data: generatedArray1801.map(value => value === 0 ? null : value),
            xAxisIndex: 1, // 指定使用第二个x轴
            yAxisIndex: 1 // 指定使用第二个y轴
          },
          {
            name: "CGM",
            data: GM2.map(value => value !== 0 ? value : null),  // 只绘制数据不等于0的点
            type: 'line',
            lineStyle: {
              color: 'rgb(45,112,141)',  // 曲线颜色
              width: 2                   // 曲线宽度
            },
            smooth: true,                // 平滑曲线
            symbol: 'none',              // 无数据点标记
            markLine: {
              symbol: ['none', 'none'],  // 无标记
              label: { show: false },    // 不显示标记线的标签
              data: [{xAxis: 288}, {xAxis: 576}, {xAxis: 864}, {xAxis: 1152}, {xAxis: 1440}, {xAxis: 1728}, {xAxis: 2010}]
            },
            markArea: {  // 添加标记区域来表示数据等于0的区域
              itemStyle: {
                color: 'rgba(115,111,111,0.5)',  // 矩形区域的颜色和透明度
                opacity: 0.3
              },
              z:100,
              data: GM2.reduce((acc, value, index) => {  // 通过遍历 GM 数据生成等于0的区域
                if (value === 0) {
                  if (acc.length === 0 || acc[acc.length - 1][1].xAxis !== index - 1) {
                    // 如果是一个新的零值区域，则创建新的矩形
                    acc.push([{xAxis: index}, {xAxis: index + 1}]);
                  } else {
                    // 如果是同一个零值区域的延续，则扩展矩形的结束点
                    acc[acc.length - 1][1].xAxis = index + 1;
                  }
                }
                return acc;
              }, [])
            },
            xAxisIndex: 1,  // 使用第一个 x 轴
            yAxisIndex: 1   // 使用第一个 y 轴
          },


        ]
      }
      myChart.setOption(option);
    }
  }

}


</script>

<style scoped>
#myChart {
  width: 10%;
}

.xingqi1 {
  margin-left: 150px;
  margin-top: -200px;


}

.xingqi2 {
  margin-left: 350px;



}

.xingqi3 {
  margin-left: 550px;


}

.xingqi4 {
  margin-left: 750px;


}

.xingqi5 {
  margin-left: 950px;


}

.xingqi6 {
  margin-left: 1150px;


}

.xingqi7 {
  margin-left: 1350px;


}



.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #ffffff;
  }
  .bg-purple {
    background: transparent;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>